import React from 'react';
import { Redirect } from 'react-router-dom'; 

class Singin extends React.Component {
    
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.singIn = this.singIn.bind(this); 
    }
    
    singIn(e){
        e.preventDefault(); 
        this.setState({redirect: true});
    }
    
    render(){
        if(this.state.redirect){
            return(<Redirect to={'/Login'}/>); 
        }

        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>REGISTRO</h1>
                <div className="workingBox">
                    <p><strong>Para comenzar el proceso de registro, por favor proporcione la siguiente información</strong></p>
                    
                    <form className="container">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label>Usuario</label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="email" />
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Password</label>
                                <input type="password" className="form-control" id="Password" placeholder="Password" /> 
                            </div>
                            <div className="form-group col-md-6">
                                <label>Confirmar Password</label>
                                <input type="password" className="form-control" id="RePassword" placeholder="Password" /> 
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>RFC</label>
                                <input type="text" className="form-control" id="RFC" aria-describedby="emailHelp" placeholder="RFC" />
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label>CASFIM</label>
                                <input type="text" className="form-control" id="CASFIM" aria-describedby="emailHelp" placeholder="CASFIM" />
                                {/* <small id="emailHelp" className="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                        </div>
                                                
                        <div className="row">
                            <div className="form-group col-md-12 text-center">
                                <button className="btn btn-primary" onClick={this.singIn}>CONTINUAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ); 
    }
}
export default Singin;