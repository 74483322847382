import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import './styles/Disclaimer.css';
import swal from 'sweetalert';

class Disclaimer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.gotoSingin = this.gotoSingin.bind(this); 
    }

    gotoSingin(){
        const cb = document.getElementById('chkAcceptTerms');
        if (cb.checked){
            this.setState({redirect: true}); 
        }
        else{
            swal('Para continuar es necesario aceptar los terminos y condiciones'); 
        }
    }
    
    render(){
        if(this.state.redirect){
            return(<Redirect to={'/Singin'}/>); 
        }
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>BIENVENIDO</h1>
                <div className="workingBox">
                    <div>
                        <p>El objetivo de este proceso es el de llevar un control de las solicitudes de afiliación... </p>
                        <p>Los datos proporcionados estarán protegidos en todo momento</p>
                        <p>Durante el proceso se le solitaran datos como </p>
                        <ul>
                            <li>RFC</li>
                            <li>CASFIM</li>
                            <li>FIEL</li>
                        </ul>
                        <p>Al aceptar los términos autoriza la consulta del Buró de Crédito etc ... </p>  
                    </div>
                    <div>
                        <p className="text-center">
                            <input className="double" type="checkbox" name="chkAcceptTerms" id="chkAcceptTerms" value="Ok" /> 
                            <span>He leido y estoy de acuerdo con los términos y condiciones</span>
                        </p>
                        <p className="text-center">
                            <button className="btn btn-primary" onClick={this.gotoSingin}>CONTINUAR</button>
                        </p>
                        <p>
                            <Link to="/Login">
                               <strong>Ya cuento con Usuario y Contraseña</strong> 
                            </Link>
                        </p>
                    </div>
                </div>
            </div>
        ); 
    }
}
export default Disclaimer;