import React from 'react';
//import swal from 'sweetalert';
import { PostData } from './services/PostData';
import swal from 'sweetalert';
import { Redirect } from 'react-router-dom';


class Login extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            usuario: '', 
            password: '', 
            redirect: false 
        }
        this.login = this.login.bind(this); 
        this.onChange = this.onChange.bind(this); 
    }

    login(e){
        e.preventDefault(); 
        if (this.state.usuario && this.state.password){
            PostData('auth', this.state).then((result) => {
                let responseJSON = result;
                
                if (responseJSON.result){
                    
                    // console.log(typeof responseJSON.datas); //object
                    sessionStorage.setItem('UserData', JSON.stringify(responseJSON.datas));  
                    this.setState({redirect: true}); 
                }
                else{
                    swal(responseJSON.message); 
                }
            });
        }
    }
    
    onChange(e){
        this.setState({[e.target.name]: e.target.value })
        // console.log(this.state)
    }

    render(){
        if (this.state.redirect || sessionStorage.getItem('UserData')){
            return(<Redirect to={'/Main'} />);
        }
                
        return(    
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>LOGIN</h1>
                <div className="workingBox">
                    <form>
                        <div className="form-group">
                            <label>Usuario</label>
                            <input type="email" className="form-control" id="usuario" name="usuario" placeholder="email" onChange={this.onChange} />
                        </div>
                        <div className="form-group">
                            <label>Password</label>
                            <input type="password" className="form-control" id="password" name="password" placeholder="Password" onChange={this.onChange} /> 
                        </div>
                                                
                        <button className="btn btn-primary" onClick={this.login}>CONTINUAR</button>
                    </form>
                </div>
            </div>
        );
    }
}
export default Login;