import React from 'react';
import {Redirect} from 'react-router-dom'; 

class Varsfin extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.saveData = this.saveData.bind(this); 
    }
    
    saveData(e){
        e.preventDefault(); 
        document.location = '/Main'; 
    }

    // Segun entendi esto se ocupa antes de renderear el componente
    componentDidMount(){       
        if(sessionStorage.getItem('UserData')){
            // var UserData = JSON.parse(sessionStorage.getItem('UserData')); // transforma cadena en storage a objeto
            // console.log(UserData.Token);  // imprime un elemento del objeto recien transformado
            // Proximo Feed dinamico de las seccones   
        }
        else{
            // cambia redirect a true
            this.setState({redirect: true}); 
        }
    }

    render(){
        if(this.state.redirect){
            return(<Redirect to={'/login'}/>)
        }
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>VARIABLES FINANCIERAS</h1>
                <div className="workingBox">
                    <p><strong>Por favor proporcione la siguiente información</strong></p>
                    
                    <form className="container">
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label>Cartera Total</label>
                                <input type="number" className="form-control" name="CarteraTotal" id="CarteraTotal" aria-describedby="emailHelp" placeholder="Cartera Total" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Capital Neto</label>
                                <input type="text" className="form-control" name="CapitalNeto" id="CapitalNeto" aria-describedby="emailHelp" placeholder="Capital Neto" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Capital Contable</label>
                                <input type="text" className="form-control" name="CapitalContable" id="CapitalContable" aria-describedby="emailHelp" placeholder="Capital Contable" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Utilidad Neta</label>
                                <input type="number" className="form-control" name="UtilidadNeta" id="UtilidadNeta" aria-describedby="emailHelp" placeholder="Utilidad Neta" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Total Activos</label>
                                <input type="text" className="form-control" name="TotalActivos" id="TotalActivos" aria-describedby="emailHelp" placeholder="Total Activos" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Total Pasivos</label>
                                <input type="text" className="form-control" name="TotalPasivos" id="TotalPasivos" aria-describedby="emailHelp" placeholder="Total Pasivos" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Cartera Vencida</label>
                                <input type="text" className="form-control" name=" CarteraVencida" id="CarteraVencida" aria-describedby="emailHelp" placeholder="Cartera Vencida" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12 text-center">
                                <button className="btn btn-primary" onClick={this.saveData}>CONTINUAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ); 
    }
}
export default Varsfin;