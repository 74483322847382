import React from 'react';
import {Redirect} from 'react-router-dom'; 

class Grales extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.saveData = this.saveData.bind(this); 
    }
    
    saveData(e){
        e.preventDefault(); 
        document.location = '/Main'; 
    }

    // Segun entendi esto se ocupa antes de renderear el componente
    componentDidMount(){       
        if(sessionStorage.getItem('UserData')){
            // var UserData = JSON.parse(sessionStorage.getItem('UserData')); // transforma cadena en storage a objeto
            // console.log(UserData.Token);  // imprime un elemento del objeto recien transformado
            // Proximo Feed dinamico de las seccones   
        }
        else{
            // cambia redirect a true
            this.setState({redirect: true}); 
        }
    }

    render(){
        if(this.state.redirect){
            return(<Redirect to={'/Login'}/>)
        }
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>DATOS GENERALES DE LA EMPRESA</h1>
                <div className="workingBox">
                    <p><strong>Por favor proporcione la siguiente información</strong></p>
                    
                    <form className="container">
                        <div className="row">
                            <div className="form-group col-md-12">
                                <label>Razón Social</label>
                                <input type="text" className="form-control" id="RazonSocial" aria-describedby="emailHelp" placeholder="Razón Social" />
                                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                        </div>
                        
                        <div className="row">
                        <div className="form-group col-md-6">
                                <label>Nombre Comercial</label>
                                <input type="text" className="form-control" id="NombreComercial" placeholder="Nombre Comercial" /> 
                            </div>
                            <div className="form-group col-md-6">
                                <label>Fecha de Constitición</label>
                                <input type="text" className="form-control" id="FechaConstitucion" placeholder="Fecha" /> 
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>RFC</label>
                                <input type="text" className="form-control" id="RFC" aria-describedby="emailHelp" placeholder="RFC" />
                                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                            <div className="form-group col-md-6">
                                <label>CASFIM</label>
                                <input type="text" className="form-control" id="CASFIM" aria-describedby="emailHelp" placeholder="CASFIM" />
                                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Sitio Web</label>
                                <input type="text" className="form-control" id="SitioWeb" aria-describedby="emailHelp" placeholder="Sitio Web" />
                                {/* <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> */}
                            </div>
                            <div className="form-group col-md-6">
                                &nbsp; 
                            </div>
                        </div>

                        <p><strong>Dirección de la Empresa</strong></p>
                        
                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>Calle</label>
                                <input type="text" className="form-control" id="Calle" aria-describedby="emailHelp" placeholder="Calle" />
                            </div>
                            <div className="form-group col-md-3">
                                <label>No. Ext</label>
                                <input type="text" className="form-control" id="NoExt" aria-describedby="emailHelp" placeholder="No. Ext" />
                            </div>
                            <div className="form-group col-md-3">
                                <label>No. Int</label>
                                <input type="text" className="form-control" id="NoInt" aria-describedby="emailHelp" placeholder="No. Int" />
                            </div>
                        </div>
                        
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label>Colonia</label>
                                <input type="text" className="form-control" id="Colonia" aria-describedby="emailHelp" placeholder="Colonia" />
                            </div>

                            <div className="form-group col-md-2">
                                <label>C.P.</label>
                                <input type="text" className="form-control" id="CP" aria-describedby="emailHelp" placeholder="CP" />
                            </div>

                            <div className="form-group col-md-3">
                                <label>Del. / Mun.</label>
                                <input type="text" className="form-control" id="NoInt" aria-describedby="emailHelp" placeholder="No. Int" />
                            </div>

                            <div className="form-group col-md-3">
                                <label>Estado</label>
                                <input type="text" className="form-control" id="Estado" aria-describedby="emailHelp" placeholder="Estado" />
                            </div>
                        </div>
                        
                        <p><strong>Información de Contacto</strong></p>

                        <div className="row">
                            <div className="form-group col-md-6">
                                <label>NombreContacto</label>
                                <input type="text" className="form-control" id="Contacto" aria-describedby="emailHelp" placeholder="Nombre del Contacto" />
                            </div>

                            <div className="form-group col-md-3">
                                <label>Telefono</label>
                                <input type="text" className="form-control" id="TelContacto" aria-describedby="emailHelp" placeholder="Telefono" />
                            </div>

                            <div className="form-group col-md-3">
                                <label>Movil</label>
                                <input type="text" className="form-control" id="MovilContacto" aria-describedby="emailHelp" placeholder="Movil" />
                            </div>

                            <div className="form-group col-md-4">
                                <label>Email</label>
                                <input type="email" className="form-control" id="EmaiContacto" aria-describedby="emailHelp" placeholder="email" />
                            </div>
                            
                            <div className="form-group col-md-4">
                                <label>¿Cómo se eneteró?</label>
                                <input type="text" className="form-control" id="MedioContacto" aria-describedby="emailHelp" placeholder="Medio contacto" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12 text-center">
                                <button className="btn btn-primary" onClick={this.saveData}>CONTINUAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ); 
    }
}
export default Grales;