import React from 'react';
import {Link, Redirect} from 'react-router-dom'; 
import './styles/Main.css';
import './styles/Badges.css'; 

class Main extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
    }
    
    // Segun entendi esto se ocupa antes de renderear el componente
    componentDidMount(){       
        if(sessionStorage.getItem('UserData')){
            // var UserData = JSON.parse(sessionStorage.getItem('UserData')); // transforma cadena en storage a objeto
            // console.log(UserData.Token);  // imprime un elemento del objeto recien transformado
            // Proximo Feed dinamico de las seccones   
        }
        else{
            // cambia redirect a true
            this.setState({redirect: true}); 
        }
    }

    render(){
        if(this.state.redirect){
            return(<Redirect to={'/login'}/>)
        }
        
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>REQUISITOS DE <span>AFILIACION</span></h1>
                <div className="workingBox">
                   <div className="container">
                       <div className="row">
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Constitución y Datos Generales 
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li><Link to="/Grales">Datos Generales</Link></li>
                                            <li>Acta Constitutva / Acta de Cambio de Denominacion</li>
                                            <li>Comprobante de Domicilio de la Sociedad <span>(Max 3 Meses)</span></li>
                                            <li>RFC de la Sociedad</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Información Financiera
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li>Estados Financieros (último semestre)</li>
                                            <li><Link to="/Varsfin">Variables Financieras</Link></li>
                                            <li><Link to="/MatrizA">Matriz Pagina 1</Link></li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Representante Legal
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li><Link to="/RLegal">Datos del Representante Legal</Link></li>
                                            <li>Poder del Representante Legal</li>
                                            <li>Identificación Oficial del Representante Legal</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Historial
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li>Consulta del Buró de Accionistas</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Cumplimientos con la Autoridad
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li>Carta de Registro a CONDUSEF, renovación</li>
                                            <li>Acuses de Recibo CNBV</li>
                                            <li>Acuses de Recibo CONDUSEF</li>
                                            <li>Opinión positiva del SAT</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Sistemas
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li>Carta Compromiso ASOFOM</li>
                                            <li>Soliciitud de  Incorporación Firmada</li>
                                            <li>Firmar Contrato NDA</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                                <div className="offer offer-radius offer-default">
                                    <div className="shape">
                                        <div className="shape-text">
                                            10%								
                                        </div>
                                    </div>
                                    <div className="offer-content">
                                        <h3 className="lead">
                                            Sistemas
                                        </h3>
                                        <ul className="seccion__reqs">
                                            <li>ERP Manejado (Automatización de Procesos)</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                       </div>
                   </div>
                </div>
            </div>
        ); 
    }
}
export default Main;