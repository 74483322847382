export function PostData(target, data){
    //let BaseURL = 'http://localhost/sasoc/asofom/api/'; // DEV
    let BaseURL = 'https://asofom.sasoc.mx/api/'; // PROD

    return new Promise((resolve, reject) => {
        fetch(BaseURL+target, {
            method: 'POST', 
            body: JSON.stringify(data)
        })
        .then((response) => response.json())
        .then((responseJson) =>{
            resolve(responseJson);
        })
        .catch((error) => {
            reject(error); 
        });
    });
}