import React from 'react';
import sasocLogo from '../images/Logo_SASOC.jpg';
class Header extends React.Component {
    render(){
        return(
            <div style={{textAlign: "center", marginBottom: "10px"}}>
                <a href="/Main">
                    <img src={sasocLogo} width="150px" height="auto" alt="Logo SASOC" /> 
                </a>
            </div>
        ); 
    }
}
export default Header;