import React from 'react';
import {Redirect} from 'react-router-dom'; 

class RLegal extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.saveData = this.saveData.bind(this); 
    }
    
    saveData(e){
        e.preventDefault(); 
        document.location = '/Main'; 
    }

    // Segun entendi esto se ocupa antes de renderear el componente
    componentDidMount(){       
        if(sessionStorage.getItem('UserData')){
            // var UserData = JSON.parse(sessionStorage.getItem('UserData')); // transforma cadena en storage a objeto
            // console.log(UserData.Token);  // imprime un elemento del objeto recien transformado
            // Proximo Feed dinamico de las seccones   
        }
        else{
            // cambia redirect a true
            this.setState({redirect: true}); 
        }
    }

    render(){
        if(this.state.redirect){
            return(<Redirect to={'/login'}/>)
        }
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>DATOS REPRESENTANTE LEGAL</h1>
                <div className="workingBox">
                    <p><strong>Por favor proporcione la siguiente información</strong></p>
                    
                    <form className="container">
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label>Nombre(s)</label>
                                <input type="text" className="form-control" id="NombreRL" aria-describedby="emailHelp" placeholder="Nombre(s)" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Apelliido Paterno</label>
                                <input type="text" className="form-control" id="ApPaternoRL" aria-describedby="emailHelp" placeholder="Apellido Paterno" />
                            </div>
                            <div className="form-group col-md-4">
                                <label>Apelldo Materno</label>
                                <input type="text" className="form-control" id="ApMaternoRL" aria-describedby="emailHelp" placeholder="Apellido Materno" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12 text-center">
                                <button className="btn btn-primary" onClick={this.saveData}>CONTINUAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ); 
    }
}
export default RLegal;