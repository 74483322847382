import React from 'react';
import {Redirect} from 'react-router-dom'; 

class MatrizA extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            redirect: false
        }
        this.saveData = this.saveData.bind(this); 
    }
    
    saveData(e){
        e.preventDefault(); 
        //document.location = '/Main'; 
        this.props.history.push('/Main'); 
    }

    // Segun entendi esto se ocupa antes de renderear el componente
    componentDidMount(){       
        if(sessionStorage.getItem('UserData')){
            // var UserData = JSON.parse(sessionStorage.getItem('UserData')); // transforma cadena en storage a objeto
            // console.log(UserData.Token);  // imprime un elemento del objeto recien transformado
            // Proximo Feed dinamico de las seccones   
        }
        else{
            // cambia redirect a true
            this.setState({redirect: true}); 
        }
    }

    render(){
        if(this.state.redirect){
            return(<Redirect to={'/login'}/>)
        }
        return(
            <div>
                <h3 id="moduleName">Módulo de Afiliación para Nuevos Socios</h3>
                <h1>MATRIZ PAG1</h1>
                <div className="workingBox">
                    <p><strong>Por favor proporcione la siguiente información</strong></p>
                    
                    <form className="container">
                        <div className="row">
                            <div className="form-group col-md-4">
                                <label>¿Cuenta con la auditoria PLD/FT del ejercicio anterior?</label>
                                <select className="form-control" name="Auditoria" id="Auditoria">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label>¿Oficial de Cumplimiento Certificado? </label>
                                <select className="form-control" name="OFCC" id="OFCC">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                            <div className="form-group col-md-4">
                                <label>¿Cuenta con Sistema de Administración de Cartera?</label>
                                <select className="form-control" name="Sistematizacon" id="Sistematizacon">
                                    <option value="0">No</option>
                                    <option value="1">Si</option>
                                </select>
                            </div>
                            <div className="form-group col-md-3">
                                <label className="inline">SIPRES</label> 
                                <input className="double inline" type="checkbox" name="SIPRES" id="SIPRES" value="Ok" /> 
                            </div>
                            <div className="form-group col-md-3">
                                <label className="inline">UNE</label> 
                                <input className="double inline" type="checkbox" name="UNE" id="UNE" value="Ok" /> 
                            </div>
                            <div className="form-group col-md-3">
                                <label className="inline">RECA</label> 
                                <input className="double inline" type="checkbox" name="RECA" id="RECA" value="Ok" /> 
                            </div>
                            <div className="form-group col-md-3">
                                <label className="inline">RECO</label> 
                                <input className="double inline" type="checkbox" name="RECO" id="RECO" value="Ok" /> 
                            </div>
                            <div className="form-group col-md-6">
                                <label>Informe Trimestral</label>
                                <input type="file" className="form-control-file" id="InformeTrimestral" />
                            </div>
                            <div className="form-group col-md-6">
                             <label>32D - Opinión de Cumpliimiento</label>
                                <input type="file" className="form-control-file" id="32D" />
                            </div>
                        </div>

                        <div className="row">
                            <div className="form-group col-md-12 text-center">
                                <button className="btn btn-primary" onClick={this.saveData}>CONTINUAR</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        ); 
    }
}
export default MatrizA;