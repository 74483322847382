import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Header from './components/Header'; 
import Disclaimer from './components/Disclaimer';
import Main from './components/Main'; 
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import Login from './components/Login';
import Singin from './components/Singin';
import Grales from './components/Grales';
import RLegal from './components/RLegal';
import Varsfin from './components/Varsfin';
import MatrizA from './components/MatrizA';

function App() {
  
  return (
    <div className="App">
      <Header /> 
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Disclaimer} />
          <Route exact path="/Disclaimer" component={Disclaimer} />
          <Route exact path="/Login" component={Login} />
          <Route exact path="/Singin" component={Singin} />
          <Route exact path="/Main" component={Main} />
          <Route exact path="/Grales" component={Grales} />
          <Route exact path="/RLegal" component={RLegal} />
          <Route exact path="/Varsfin" component={Varsfin} />
          <Route exact path="/MatrizA" component={MatrizA} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
